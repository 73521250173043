import request from '@/utils/request'
const preFixUrl = '/push'
//process.env.NODE_ENV === 'development' ? "/zcp9018Api/mealOrder" : '/fft/mealOrder'
export const queryReservationInfo = (data) => {
  return request({
    url: `${preFixUrl}/reservation/queryReservationInfo`,
    method: 'post',
    headers: {
      ['Content-Type']: 'application/json',
    },
    data,
  })
}

export const queryReservationInfoByNum = (data) => {
  return request({
    url: `${preFixUrl}/reservation/queryReservationInfoByNum`,
    method: 'post',
    headers: {
      ['Content-Type']: 'application/json',
    },
    data,
  })
}
export const exportReservation = (data) => {
  return request({
    url: `${preFixUrl}/reservation/exportReservation`,
    method: 'post',
    headers: {
      ['Content-Type']: 'application/json',
    },
    responseType: 'blob',
    data,
  })
}
